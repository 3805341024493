import {request} from './request'
import Cookies from 'js-cookie'
import qs from 'qs'

export function getOrders(page,tab_key,rows_per_page=10) {
    var maker_id = Cookies.get("maker_id");
    return request({url:'/test/test.php?a=getOrders',params:{page,tab_key,maker_id,rows_per_page}});
}
export function getOrderDetail(page,id) {
    let maker_id = Cookies.get("maker_id");
    return request({url:'/test/test.php?a=getOrderDetail',params:{page,id,maker_id}});
}
export function setOrderLogistics(data){
    data.maker_id= Cookies.get("maker_id");
    return request({url:'/test/test.php?a=setOrderLogistics',method:'post',data:qs.stringify(data)});
}
export function getIncomeDetails(id){
    let maker_id = Cookies.get("maker_id");
    return request({url:'/test/test.php?a=getIncomeDetails',params:{id,maker_id}});
}
export function getOverview(){
    let maker_id = Cookies.get("maker_id");
    return request({url:'/test/test.php?a=getOverview',params:{maker_id}});
}
